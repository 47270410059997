import { BASE_URL } from "../../global";
import axiosWithToken from "./axiosTokenConfig";

let cache = {};

const convertDegreeCandOhm = async (val, from, to, row, BASE) => {
  try {
    let referencedData = null;
    // get referernced data from database
    if (cache["unitConvDegreeCOhm"]?.[row[1 + BASE]]) {
      referencedData = cache["unitConvDegreeCOhm"]?.[row[1 + BASE]];
    } else {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * from unitConvDegreeCOhm where degreeC=${
        row[1 + BASE]
      } limit 1`;
      referencedData = await axiosWithToken.post(url, { query });
      referencedData = referencedData?.data?.[0];
      cache["unitConvDegreeCOhm"] = cache["unitConvDegreeCOhm"] || {};
      cache["unitConvDegreeCOhm"][row[1 + BASE]] = referencedData;
    }

    // conversion formula
    val =
      (val - referencedData?.ohm) / referencedData?.diff +
      Number(row[1 + BASE]);
    return val;
  } catch (e) {
    console.log("Error in customUnitConversion", e);
    return val;
  }
};

const convertDegreeCandMV = async (val, from, to, row, BASE, referenceData) => {
  console.log(
    "convertDegreeCandMV",
    { val, from, to, row, BASE },
    referenceData?.srfInstruments?.sensorType
  );

  try {
    let referencedData = null;

    if (cache["unitConvMvDegreeC"]?.[val]) {
      referencedData = cache["unitConvMvDegreeC"]?.[val];
    } else {
      
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT *, CASE WHEN ABS(c0 - ${val}) <= 0.03 THEN 'c0' WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c9 - ${val}) <= 0.03 THEN 'c9' ELSE NULL END AS match_value, CASE WHEN ABS(c0 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c9' ELSE 'c10' END AS upper_value FROM unitConvMvDegreeC WHERE type = 'K-Type' AND (C0 IS NOT NULL  AND C1 IS NOT NULL  AND C2 IS NOT NULL  AND C3 IS NOT NULL  AND C4 IS NOT NULL AND C5 IS NOT NULL AND C6 IS NOT NULL AND C7 IS NOT NULL AND C8 IS NOT NULL AND C9 IS NOT NULL) AND ( (ABS(c0 - ${val}) <= 0.03) OR (ABS(c1 - ${val}) <= 0.03) OR (ABS(c2 - ${val}) <= 0.03) OR (ABS(c3 - ${val}) <= 0.03) OR (ABS(c4 - ${val}) <= 0.03) OR (ABS(c5 - ${val}) <= 0.03) OR (ABS(c6 - ${val}) <= 0.03) OR (ABS(c7 - ${val}) <= 0.03) OR (ABS(c8 - ${val}) <= 0.03) OR (ABS(c9 - ${val}) <= 0.03) ) order by id  asc limit 1 `;
  
      referencedData = await axiosWithToken.post(url, { query });
      referencedData = referencedData?.data?.[0];      
      cache["unitConvMvDegreeC"] = cache["unitConvMvDegreeC"] || {};
      cache["unitConvMvDegreeC"][val] = referencedData;
    }
      console.log(referencedData);

        const { match_value, upper_value, degreeC, ...coefficients } = referencedData;
  
        const lower_Value = coefficients[match_value];
        const upper_Value = coefficients[upper_value];
  
        const lower_temp = parseFloat(degreeC) + parseInt(match_value.replace("c", ""));
        const upper_temp = parseFloat(degreeC) + parseInt(upper_value.replace("c", ""));
  
        let calculateValue =
          ((val - lower_Value) / (upper_Value - lower_Value) *
            (upper_temp - lower_temp)) +
          lower_temp;
          
          if(calculateValue){
              return Number(calculateValue.toFixed(3));
          } else {
           return val;
           }

  } catch (e) {
    console.log("Error in convertDegreeCandMV", e);
    return val;
  }
};

const convertGramAndNewton = (val, from, to, row, BASE) => {
  console.log("convertGramAndNewton, val, from", val, from, from === "g");
  if (from === "g") return val * (1000 / 9.80665);
  if (from === "N") return val * (9.80665 / 1000);
};

export const customUnitConvCombinations = [
  ["°C", "Ω"],
  ["Ω", "°C"],
  ["°C", "mV"],
  ["mV", "°C"],
  ["N", "g"],
  ["g", "N"],
];
export const containsCustomUnitPair = (inputPair) => {
  const inputSet = new Set(inputPair);
  return customUnitConvCombinations.some(
    (pair) =>
      new Set(pair).size === inputSet.size &&
      pair.every((unit) => inputSet.has(unit))
  );
};

export const customUnitConversion = async (
  val,
  from,
  to,
  row = null,
  BASE = null,
  referenceData = []
) => {
  if (from === to) return val;

  // 1. case 1: Ω to °C, and vice versa
  if (["°C", "Ω"].includes(from) && ["°C", "Ω"].includes(to)) {
    return await convertDegreeCandOhm(val, from, to, row, BASE);
  }

  // 2. case 2: mV to °C
  if (["mV"].includes(from) && ["°C"].includes(to)) {
    return await convertDegreeCandMV(val, from, to, row, BASE, referenceData);
  }

  // 3. Newton to gram and vice versa
  if (["N", "g"].includes(from) && ["N", "g"].includes(to)) {
    return convertGramAndNewton(val, from, to, row, BASE);
  }

  // clean cache after 3 minutes
  setTimeout(() => {
      cache = {};
  }, 180000);
};
